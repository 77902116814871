import FileMetaDTO from "@/dto/files/FileMetaDTO";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import PersonDTO from "@/dto/person/PersonDTO";

export default class CompanyDetailsDTO {
    public id: number | null = null;

    public name: string | null = null;

    public ein: string | null = null;

    public einVerificationProof: FileMetaDTO | null = null;

    public einVerificationDate: Date | null = null;

    public dba: string | null = null;

    public nationalTin: string | null = null;

    public website: string | null = null;

    public phone: string | null = null;

    public federalTaxClass: string | null = null;

    public certificateOfIncorporation: FileMetaDTO | null = null;

    public filingReceipt: FileMetaDTO | null = null;

    public naicsCode: number | null = null;

    public reserveAgreement: SignableDocumentDTO | null = null;

    public booksProLink: string | null = null;

    public officer: PersonDTO | null = null;

    public bsoId: string | null = null;
}

export class CompanyDocumentsDTO {
    ss4: FileMetaDTO | null = null;
    certificateOfIncorporation: FileMetaDTO | null = null;
    fillingReceipt: FileMetaDTO | null = null

    public bsoId: string | null = null;

    constructor(init?: CompanyDocumentsDTO) {
        Object.assign(this, init)
    }
}

export class UploadCompanyDocumentsPayload extends CompanyDocumentsDTO{
    companyId: number | null = null

    constructor(init?: Partial<UploadCompanyDocumentsPayload>) {
        super();
        Object.assign(this, init)
    }
}